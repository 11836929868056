<template>
  <div>
    <div class="center-between mb-4">
      <h1 class="m-0" v-text="$t('image.label', 2)" />
      <button
        class="small"
        @click="$store.dispatch('modals/openModal', { entity: {
          name: '',
          file: null,
          type: 'image',
        }, modal: 'editImage' })"
      >
        +
      </button>
    </div>

    <entity-table
      v-model:sort-by="sortBy"
      v-model:sort-direction="sortDirection"
      :fields="fields"
      :entities="entities"
      :current-page="currentPage"
      :total-items="totalItems"
      :loading="loadingEntities"
      @update:page="currentPage = $event; loadEntities()"
      @update:sort="loadEntities()"
      @click:delete="$store.dispatch('modals/openModal', { modal: 'deleteImage', entity: $event })"
      @click:edit="$store.dispatch('modals/openModal', { modal: 'editImage', entity: $event })"
    >
      <template #cell(contentUrl)="data">
        <product-icon :icon="data.entity" class="h-8 w-8 object-contain" />
      </template>
    </entity-table>

    <edit-image-modal @update="loadEntities" />
    <delete-modal type="deleteImage" @update="loadEntities" />
  </div>
</template>

<script>
import useLoadEntities from '@/hooks/api/loadEntities';
import useEntityFields from '@/hooks/api/fields';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import ProductIcon from '@/components/shared/ProductIcon.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import EditImageModal from '@/components/entities/image/EditImageModal.vue';

export default {
  name: 'ImagesIndex',
  components: {
    EditImageModal,
    DeleteModal,
    ProductIcon,
    EntityTable,
  },
  setup() {
    const { image: fields } = useEntityFields();
    const {
      loadEntities,
      totalItems,
      currentPage,
      sortDirection,
      loadingEntities,
      entities,
      sortBy,
    } = useLoadEntities('/images', {
      sort: 'name',
      defaultParams: {
        is: 'image',
      },
    });

    return {
      fields,
      totalItems,
      currentPage,
      sortDirection,
      loadingEntities,
      entities,
      sortBy,
      loadEntities,
    };
  },
};
</script>
