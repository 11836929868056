<template>
  <div>
    <h1>
      {{ $t('icon.label', 2) }}
    </h1>

    <entity-table
      v-model:sort-by="sortBy"
      v-model:sort-direction="sortDirection"
      :fields="fields"
      :entities="entities"
      :current-page="currentPage"
      :total-items="totalItems"
      :loading="loadingEntities"
      @update:page="currentPage = $event; loadEntities()"
      @update:sort="loadEntities()"
    >
      <template #cell(contentUrl)="data">
        <product-icon :icon="data.entity" class="h-8 w-8 object-contain" />
      </template>
    </entity-table>
  </div>
</template>

<script>
import useLoadEntities from '@/hooks/api/loadEntities';
import useEntityFields from '@/hooks/api/fields';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import ProductIcon from '@/components/shared/ProductIcon.vue';

export default {
  name: 'IconsIndex',
  components: { ProductIcon, EntityTable },
  setup() {
    const { icon: fields } = useEntityFields();
    const {
      loadEntities,
      totalItems,
      currentPage,
      sortDirection,
      loadingEntities,
      entities,
      sortBy,
    } = useLoadEntities('/icons', { sort: 'name' });

    return {
      fields,
      totalItems,
      currentPage,
      sortDirection,
      loadingEntities,
      entities,
      sortBy,
      loadEntities,
    };
  },
};
</script>
