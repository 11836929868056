<template>
  <img :src="`${baseUrl}${icon.contentUrl}`" :alt="icon.name">
</template>

<script>
export default {
  name: 'ProductIcon',
  props: {
    icon: {
      type: Object,
      required: true,
    },
    maxWidth: {
      type: String,
      required: false,
      default: 'null',
    },
  },
  data() {
    return {
      baseUrl: window.env.VUE_APP_API_URL || process.env.VUE_APP_API_URL,
    };
  },
};
</script>

<style scoped>

</style>
