<template>
  <div>
    <div class="tabs">
      <router-link
        v-for="link in links"
        :key="link.id"
        :to="link.route ? link.route : { path: link.path }"
        class="tab"
        v-text="link.text"
      />
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  name: 'RouterTabs',
  props: {
    /**
     * List if tabs
     * @example [{ id: 'foo', text: 'Foo', route: { name: 'route-name' } }]
     * @example [{ id: 'bar', text: 'Bar', route: '/route-path' }]
     */
    links: { type: Array, default: () => ([]) },
  },
};
</script>

<style lang="scss" scoped>
</style>
