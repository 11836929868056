<template>
  <router-tabs :links="links">
    <router-view class="pt-4" />
  </router-tabs>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import RouterTabs from '@/components/utils/RouterTabs.vue';

export default {
  name: 'ImagesMain',
  components: { RouterTabs },
  setup() {
    const { t } = useI18n();
    const links = ref([
      { id: 'images-index', route: { name: 'images' }, text: t('image.label', 2) },
      { id: 'icons-index', route: { name: 'icons' }, text: t('icon.label', 2) },
    ]);

    return { links };
  },
};
</script>
