<template>
  <modal-form
    v-slot="{ properties, errors }"
    type="editImage"
    :save-func="save"
    :options="{
      properties: {
        name: '',
        file: null,
      },
      postUri: '/images',
      label: $t('image.label')
    }"
  >
    <fancy-input v-model="properties.name" :label="$t('general.field.name')" :error="errors.name" />
    <div class="dropinput">
      <input id="file" type="file" @change="(ev) => drop(ev, properties)">
      <label
        ref="dropRef"
        for="file"
        class="droparea"
        @dragenter.prevent.stop="dragenter"
        @dragleave.prevent.stop="dragleave"
        @dragover.prevent.stop="dragover"
        @drop="(ev) => drop(ev, properties)"
      >
        <img v-if="contentUrl" :src="contentUrl" class="w-full h-24 object-contain mx-auto">
        <span v-else v-t="'general.action.dropFile'" />
      </label>
    </div>

  </modal-form>
</template>
<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { notify } from '@kyvg/vue3-notification';
import axios from '@/services/axios';
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import ProductIcon from '@/components/shared/ProductIcon.vue';

export default {
  name: 'EditImageModal',
  components: {
    ProductIcon,
    FancyInput,
    ModalForm,
  },
  emits: ['update'],
  setup(props, { emit }) {
    const store = useStore();
    const entity = computed(() => store.state.modals.entity);
    const dropRef = ref(null);
    const file = ref(null);
    const contentUrl = computed(() => {
      if (file.value) {
        return URL.createObjectURL(file.value);
      }
      if (entity.value?.contentUrl) {
        return `${window.env.VUE_APP_API_URL || process.env.VUE_APP_API_URL}${entity.value.contentUrl}`;
      }
      return '';
    });

    const dragenter = () => {
      dropRef.value.style.borderColor = 'var(--color-primary)';
    };
    const dragleave = () => {
      dropRef.value.style.borderColor = '';
    };
    const dragover = (ev) => {
      ev.preventDefault();
    };
    const drop = (ev) => {
      ev.preventDefault();
      let files = [];
      if (ev.dataTransfer) {
        const { dataTransfer } = ev;
        files = dataTransfer.files;
      } else if (ev.target.files) {
        console.log(ev.target.files);
        files = ev.target.files;
      }

      if (files.length) {
        [file.value] = files;
      }
    };

    const save = async (newValue, originalValue, errors) => {
      const form = new FormData();
      form.append('name', newValue.name);
      if (file.value) {
        form.append('file', file.value);
      }

      try {
        if (entity.value?.['@id']) {
          await axios.put(entity.value['@id'], form);
        } else {
          await axios.post('/images', form);
        }
        await store.dispatch('modals/closeModal', 'editImage');
        emit('update');
      } catch (err) {
        if (err.response?.status === 422 && err.response?.data?.violations != null) {
          err.response.data.violations.forEach((v) => {
            if (v.propertyPath && v.message) {
              errors.value[v.propertyPath] = v.message;
            }
          });
          notify({
            title: 'Ungültige Daten',
            type: 'warn',
          });
        } else {
          console.error(err, err.message, err.response);
          notify({
            title: 'Fehler beim Speichern',
            text: err?.response?.data?.['hydra:description'] ?? null,
            type: 'error',
          });
        }
      }
    };

    return {
      entity,
      contentUrl,
      dropRef,
      dragenter,
      dragleave,
      dragover,
      drop,
      save,
    };
  },
};
</script>
